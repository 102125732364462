import React from "react";

// MUI
import {
  List,
  ListItem,
  Menu,
  MenuItem,
  //   Hooks
  makeStyles,
  createStyles,
  withStyles,
  //   Interfaces
  Theme,
} from "@material-ui/core";

// Components
import Title from "../Title";
import { orderBy } from "lodash";

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
    },
  })
);

// Custom Components
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme: Theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

// Interfaces
interface IMenuItem {
  label: string;
  data: any;
}

interface IMenuSelector {
  menuItems: IMenuItem[];
  selector: (e: any) => void;
  title: string;
}

const MenuSelector = (props: IMenuSelector) => {
  const classes = useStyles();

  const { menuItems, selector, title } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = ({
    index,
    data,
  }: {
    index: number;
    data: any;
  }) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    selector(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (menuItems.length) {
      handleMenuItemClick({ index: 0, data: menuItems[0].data });
    }
  }, [menuItems]);

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="Device settings">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="Select a client"
          onClick={handleClickListItem}
        >
          <Title
            title={
              menuItems[selectedIndex]
                ? menuItems[selectedIndex].label
                : `${title}`
            }
            heading={`${title}`}
            dense
            fixedHeight
          />
        </ListItem>
      </List>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* Menu options */}
        {orderBy(menuItems, ["label"]).map((option, index) => (
          <StyledMenuItem
            key={`${option.label}_${index}`}
            selected={index === selectedIndex}
            onClick={(event) =>
              handleMenuItemClick({ index, data: option.data })
            }
          >
            {`${index + 1}. ${option.label}`}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

export default MenuSelector;
