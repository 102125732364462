import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useAnimations = makeStyles((theme: Theme) =>
  createStyles({
    "@keyframes rotate": {
      from: {
        transform: "rotate(0deg)"
      },
      to: {
        transform: "rotate(360deg)"
      }
    },

    "@keyframes fadeIn": {
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      }
    },

    "@keyframes fadeOut": {
      from: {
        opacity: 1
      },
      to: {
        opacity: 0
      }
    },

    rotate: {
      transform: "rotate(0deg)",
      animation: `$rotate 2500ms ${theme.transitions.easing.easeInOut} 200ms infinite`
    },

    fadeIn: {
      opacity: 0,
      animation: `$fadeIn 500ms ${theme.transitions.easing.easeIn} 100ms`,
      animationFillMode: "forwards",
      WebkitAnimationFillMode: "forwards"
    },

    fadeOut: {
      opacity: 1,
      animation: `$fadeOut 500ms ${theme.transitions.easing.easeOut} 100ms`,
      animationFillMode: "forwards",
      WebkitAnimationFillMode: "forwards"
    }
  })
);
