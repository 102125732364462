import React from "react";
import { connect } from "react-redux";

// RRF
import { storage } from "../firebase/firebase";
import { useFirebase, isLoaded } from "react-redux-firebase";

// Interfaces
import { IProfile } from "../interfaces";

// MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Theme,
  makeStyles,
} from "@material-ui/core";

// MUI Icons
import { Clear } from "@material-ui/icons";

// Util
import clsx from "clsx";
import { useSnackbar } from "notistack";

// Components
import ProfileAvatar from "../components/ProfileAvatar/ProfileAvatar";
import FileUploader from "../components/FileUploader/FileUploader";

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
  },
  textField: {
    width: 250,
  },
  buttonContainer: {
    padding: "1rem",
  },
}));

interface Props {
  profile: IProfile;
}

const Profile = (props: Props) => {
  const {
    profile: { avatar, firstName, lastName, email, contactEmail },
  } = props;

  const classes = useStyles();
  const firebase = useFirebase();

  // Status
  const [clearAvatarDialog, setClearAvatarDialog] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const defaultProfile = {
    avatar: avatar || "",
    firstName,
    lastName,
    email,
    contactEmail: contactEmail || "",
  };

  const [values, setValues] = React.useState(defaultProfile);
  const [deleting, setDeleting] = React.useState(false);

  const updateProfile = () => {
    firebase.updateProfile(values);
    enqueueSnackbar("Profile Updated Successfully!", { variant: "success" });
  };

  const clearAvatar = () => {
    if (avatar) {
      setDeleting(true);
      const storageRef = storage.refFromURL(avatar);
      storageRef.delete().then((res: any) => {
        firebase.updateProfile({ avatar: "" });
        setClearAvatarDialog(false);
        enqueueSnackbar("Avatar Cleared", { variant: "info" });
        setDeleting(false);
      });
    }
  };

  if (!isLoaded(!props.profile)) {
    return <LinearProgress style={{ width: "100%" }} />;
  }

  return (
    <Grid
      container
      style={{ display: "flex", flexWrap: "wrap", padding: "2rem 1rem" }}
    >
      {/* Avatar if exists */}
      {avatar ? (
        <>
          <Grid
            item
            xs={12}
            className={classes.textFieldContainer}
            style={{ position: "relative" }}
          >
            <ProfileAvatar src={avatar} />
            <IconButton
              size="small"
              onClick={() => setClearAvatarDialog(true)}
              style={{ position: "absolute", bottom: "-.5rem" }}
            >
              <Clear />
            </IconButton>
          </Grid>
          <Divider style={{ margin: "1rem", width: "100%" }} />
        </>
      ) : (
        <Grid item xs={12} className={classes.textFieldContainer}>
          <FileUploader variant="avatar" />
        </Grid>
      )}

      <Grid item xs={12} sm={6} className={classes.textFieldContainer}>
        <TextField
          className={classes.textField}
          value={values.firstName}
          label="First Name"
          variant="outlined"
          onChange={(e: any) =>
            setValues({ ...values, firstName: e.target.value })
          }
        />
      </Grid>

      <Grid item xs={12} sm={6} className={classes.textFieldContainer}>
        <TextField
          className={classes.textField}
          value={values.lastName}
          label="Last Name"
          variant="outlined"
          onChange={(e: any) =>
            setValues({ ...values, lastName: e.target.value })
          }
        />
      </Grid>

      <Grid item xs={12} sm={6} className={classes.textFieldContainer}>
        <TextField
          className={classes.textField}
          value={values.email}
          label="Login Email"
          variant="outlined"
          disabled={true}
          helperText="Login email cannot be changed"
        />
      </Grid>

      <Grid item xs={12} sm={6} className={classes.textFieldContainer}>
        <TextField
          className={classes.textField}
          value={values.contactEmail}
          label="Contact Email"
          variant="outlined"
          helperText={"Optional: if different than ogin email"}
          onChange={(e: any) =>
            setValues({ ...values, contactEmail: e.target.value })
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        className={clsx(classes.textFieldContainer, classes.buttonContainer)}
      >
        <Button onClick={() => updateProfile()}>Update Profile</Button>
      </Grid>

      {/* Clear Dialog */}
      <Dialog
        open={clearAvatarDialog}
        onClose={() => setClearAvatarDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action is permanent. Are you sure you wanto to clear your
            avatar?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            color="secondary"
            onClick={() => setClearAvatarDialog(false)}
            autoFocus
          >
            Exit
          </Button>
          <Button
            onClick={() => clearAvatar()}
            color="primary"
            disabled={deleting}
          >
            {deleting ? (
              <LinearProgress style={{ width: "100%" }} />
            ) : (
              "Clear Avatar"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default connect((state: any) => ({
  profile: state.firebase.profile,
}))(Profile);
