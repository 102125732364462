import React, { useCallback } from "react";

// MUI
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  List,
  Toolbar,
  Slide,
  Typography,
} from "@material-ui/core";

// MUI Styles
import { makeStyles } from "@material-ui/core/styles";

// MUI Icons
import { Close } from "@material-ui/icons";

// Components
import Device from "./Device";
import { Websocket } from "./Websocket";

// Interfaces
import { IPoint } from "../../interfaces";
import { isEmpty } from "lodash";

// Styles
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

// FX
const Transition: any = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

// Interface
interface IDeviceDialog {
  open: boolean;
  handleClose: () => void;
  selectedDevice: any;
}

// Component
export default function DeviceDialog({
  open,
  handleClose,
  selectedDevice,
}: IDeviceDialog) {
  const classes = useStyles();

  // Websocket
  const [socketData, setSocketData] = React.useState({
    id: "",
    name: "",
    points: [],
  });

  // Parse incoming socket data
  const handleSocketData = useCallback(
    (lastMessage: any) => {
      setSocketData(JSON.parse(lastMessage.data));
    },
    [setSocketData]
  );

  // Cleanup
  React.useEffect(() => {
    return () => {
      setSocketData({
        id: "",
        name: "",
        points: [],
      });
    };
  }, []);

  if (!selectedDevice || isEmpty(selectedDevice)) return null;

  //
  console.log(selectedDevice.points);

  // Render
  return (
    <div>
      <Websocket
        endpoint={selectedDevice.id}
        socketData={socketData}
        handleSocketData={handleSocketData}
      />

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {selectedDevice.name}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          {selectedDevice.points.map((point: IPoint) => {
            return (
              <Device
                key={point.id}
                point={point}
                socketData={socketData}
                name={point.name}
              />
            );
          })}
        </List>
      </Dialog>
    </div>
  );
}
