import React, { useCallback } from "react";
import { connect } from "react-redux";

import { functions } from "../../firebase/firebase";

// MUI
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";

// MUI Icons
import {
  CheckCircle,
  Notifications,
  NotificationsOff,
} from "@material-ui/icons";

// MUI Colors
import { lightGreen } from "@material-ui/core/colors";

// Interfaces
import { IPoint, IProfile } from "../../interfaces";

// Components
import HistoryChart from "../Charts/HistoryChart";

// Util
import clsx from "clsx";
import moment from "moment";
import MuteDialog from "./MuteDialog";
import { push } from "react-router-redux";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  acknowledged: {
    background: lightGreen[500],
  },
  dialogContent: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    width: 300,
    padding: "1rem",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  defaultTitle: {
    color: "rgba(0,0,0,.5)",
  },
  checkedTitle: {
    color: "white",
  },
  link: {
    padding: ".5rem",
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

// Fetch
const fetchHistory = functions.httpsCallable("fetchHistory");

interface IPointDialog {
  open: boolean;
  handleClose: () => void;
  pointData: IPoint | any;
  handleAcknowledgeAlarm: (id: string[]) => any;
  handlePointData: (id: string) => any;
  error: { title: string; message: string };
  setError: any;
  // Reducer
  profile: IProfile;
  reroute: (route: string) => void;
}

const PointDialog = ({
  open,
  handleClose,
  pointData,
  handleAcknowledgeAlarm,
  handlePointData,
  error,
  setError,
  //
  profile: { email, api },
  reroute,
}: IPointDialog) => {
  const classes = useStyles();

  // Destructure
  const {
    id,
    currentAlarm: { acknowledged, alarmMessage, eventId, userAction } = {
      acknowledged: false,
      alarmMessage: "",
      eventId: "",
      userAction: {},
    },
    muteStatus: { muted } = { muted: false },
    name = "",
    data: { status, displayString } = { status: "", displayString: "" },
  } = pointData;

  const [historyData, setHistoryData] = React.useState([]);
  // eslint-disable-next-line
  const [historyError, setHistoryError] = React.useState({});

  const [fetching, setFetching] = React.useState(false);
  const [fetchingHistory, setFetchingHistory] = React.useState(false);

  // Muting
  const [openMuteDialog, setOpenMuteDialog] = React.useState(false);
  const [mutingPoint] = React.useState(false);

  // Check mobile
  const mobile = useMediaQuery("(max-width:600px)");

  const cleanup = () => {
    setHistoryData([]);
  };

  React.useEffect(() => {
    if (id) {
      setFetchingHistory(true);
      fetchHistory({
        route: `history/point/${id}`,
        api,
        end: Math.floor(new Date().getTime() / 1000),
        start: Math.floor(new Date().getTime() / 1000) - 2592000,
      })
        .then((res: any) => {
          if (res.data && res.data.data) {
            setHistoryData(res.data.data);
          }
          setFetchingHistory(false);
        })
        .catch((e: any) => {
          setHistoryError(e);
          setFetchingHistory(false);
        });
    }

    return () => {
      setError({ title: "", message: "" });
      cleanup();
    };
    // eslint-disable-next-line
  }, [pointData]);

  const handleCloseDialog = useCallback(() => {
    handleClose();
    cleanup();
    // eslint-disable-next-line
  }, []);

  const acknowledge = (ids: string[]) => {
    setFetching(true);
    handleAcknowledgeAlarm(ids).then((res: any) => {
      handlePointData(id).then((res: any) => {
        setFetching(false);
      });
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        fullScreen={mobile}
        keepMounted={true}
      >
        <DialogTitle
          className={clsx({
            [classes.acknowledged]: pointData.currentAlarm && acknowledged,
          })}
        >
          {/* Error Message */}
          {error.message && (
            <>
              <Typography variant="h4" style={{ fontSize: "1rem" }}>
                {error.title}
              </Typography>
              <Typography variant="h6">{error.message}</Typography>
            </>
          )}

          {!pointData.name ? (
            <LinearProgress
              variant={error.message ? "determinate" : "indeterminate"}
              style={{ width: "100%" }}
            />
          ) : (
            <div
              className={clsx({
                [classes.buttonContainer]: true,
                [classes.defaultTitle]: !acknowledged,
                [classes.checkedTitle]: acknowledged,
              })}
            >
              <Typography variant="h6">{name}</Typography>

              {/* Handle Mute */}
              <div style={{ display: "flex" }}>
                <Tooltip
                  title={muted ? "Point Muted" : "Mute Point"}
                  placement="bottom"
                >
                  <span>
                    <IconButton
                      onClick={() => setOpenMuteDialog(true)}
                      disabled={Boolean(fetching)}
                    >
                      {mutingPoint ? (
                        <CircularProgress size={20} />
                      ) : muted ? (
                        <NotificationsOff
                          className={clsx({
                            [classes.buttonContainer]: true,
                            [classes.defaultTitle]: !acknowledged,
                            [classes.checkedTitle]: acknowledged,
                          })}
                        />
                      ) : (
                        <Notifications
                          className={clsx({
                            [classes.buttonContainer]: true,
                            [classes.defaultTitle]: !acknowledged,
                            [classes.checkedTitle]: acknowledged,
                          })}
                        />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>

                {/* Handle Acknowledge */}
                <Tooltip
                  title={
                    acknowledged ? "Point Acknowledged" : "Acknowledge Point"
                  }
                  placement="bottom"
                >
                  <span>
                    <IconButton
                      onClick={() => acknowledge([eventId])}
                      disabled={Boolean(fetching) || acknowledged}
                    >
                      {fetching ? (
                        <CircularProgress size={20} />
                      ) : (
                        <CheckCircle
                          className={clsx({
                            [classes.defaultTitle]: !acknowledged,
                            [classes.checkedTitle]: acknowledged,
                          })}
                        />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            </div>
          )}
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogContent}>
            {pointData.data ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {/* Value */}
                <Typography variant="h6">Value: {displayString}</Typography>
                {/* Status */}
                Status: {status}
                {/* Acknowledged */}
                {acknowledged && Boolean(userAction.time) ? (
                  <Typography variant="caption">
                    {`(acknowledged ${moment(userAction.time * 1000).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )})`}
                  </Typography>
                ) : null}
                <Divider style={{ margin: "1rem 0" }} />
              </div>
            ) : (
              <span />
            )}

            {/* Current Alarm Data */}
            {pointData.currentAlarm && (
              <>
                <div>
                  <Typography variant="h6">Alarm</Typography>
                  {alarmMessage}
                </div>
                <Divider style={{ margin: "1rem 0" }} />
              </>
            )}

            {fetchingHistory ? (
              <LinearProgress style={{ width: "100%" }} />
            ) : Boolean(historyData.length) ? (
              <>
                <Typography variant="h6">Point History</Typography>
                <div style={{ marginLeft: "-2.5rem" }}>
                  <HistoryChart
                    data={historyData}
                    xDataKey="value"
                    xDataKeyLabel="timestamp"
                  />
                </div>
              </>
            ) : null}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <a
              onClick={() => reroute(`/point/${pointData.id}`)}
              className={classes.link}
            >
              Point Alarm
            </a>
          </div>
        </DialogContent>

        {/* Close Dialog */}
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Mute Dialog */}
      <MuteDialog
        open={openMuteDialog}
        setOpen={setOpenMuteDialog}
        data={pointData}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    profile: state.firebase.profile,
  }),
  (dispatch: any) => ({
    reroute: (route: string) => dispatch(push(route)),
  })
)(PointDialog);
