import React, { useRef, useEffect } from "react";

// MUI
import { ListItem, ListItemText, makeStyles } from "@material-ui/core";

// MUI Colors
import { lightBlue } from "@material-ui/core/colors";

// MUI Icons
import { KeyboardArrowRight } from "@material-ui/icons";

// Animations
import styled, { css, keyframes } from "styled-components";

// Interfaces
import { IPoint } from "../../interfaces";

// Styles
const flashUpdate = keyframes`
  0% {
    background: white;
  }
  50% {
    background: ${lightBlue[100]};
  }
  100% {
    background: white;
  }
`;

const animation = css`
  animation: ${flashUpdate} 1s ease-out;
`;

const AnimatedComponent: any = styled(ListItem)<{ flash: string }>`
  ${props => (props.flash === "true" ? animation : false)}
`;

const useStyles = makeStyles(() => ({
  secondary: {
    display: "flex",
    alignItems: "center",
    height: 24
  }
}));

// Interfaces
interface IDeviceType {
  point: IPoint;
  socketData: any;
  name: string;
}

export default function Device({ point, socketData, name }: IDeviceType) {
  // Classes
  const classes = useStyles();

  // Store data in ref
  const streamingData = useRef(point.data.displayString);

  // Display flash
  const [flash, setFlash] = React.useState(false);

  // eslint-disable-next-line
  useEffect(() => {
    // Set incoming data to the ref if there's a change
    if (point.id === socketData.id) {
      setFlash(true);
      if (streamingData.current !== socketData.data.value) {
        streamingData.current = socketData.data.displayString;
      }
    } else {
      // Wait a second to let the animation finish, then remove class
      const timer = setTimeout(() => {
        setFlash(false);
      }, 1000);

      // Clean up timer on unmount
      return () => clearTimeout(timer);
    }
  });

  return (
    <AnimatedComponent flash={flash.toString()}>
      <ListItemText
        primary={name}
        secondary={
          point.data.displayString === streamingData.current ? (
            point.data.displayString
          ) : (
            <>
              {point.data.displayString} <KeyboardArrowRight />{" "}
              <span style={{ fontWeight: "bolder" }}>
                {streamingData.current}
              </span>
            </>
          )
        }
        classes={{ secondary: classes.secondary }}
      />
    </AnimatedComponent>
  );
}
