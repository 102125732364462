import React from "react";

// MUI
import { Grid, Button, makeStyles, Theme } from "@material-ui/core";
import { connect } from "react-redux";
import { push } from "connected-react-router";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    padding: "2rem",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "1rem",
  },
  button: {
    width: 210,
  },
}));

interface IAlarms {
  reroute: (route: string) => void;
}

const Alarms = ({ reroute }: IAlarms) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={6} className={classes.buttonContainer}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => reroute("/alarms/current")}
          >
            Current Alarms
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.buttonContainer}>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.button}
            onClick={() => reroute("/alarms/history")}
          >
            Historical Alarms
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default connect(null, (dispatch: any) => ({
  reroute: (route: string) => dispatch(push(route)),
}))(Alarms);
