import React, { memo } from "react";
import { functions } from "../../firebase/firebase";

// MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  makeStyles,
  Theme,
} from "@material-ui/core";

// MUI Icons
import { Remove, Add, Event } from "@material-ui/icons";

// Datetime
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";

// Util
import moment from "moment";

// Interfaces
import { IPoint, IProfile } from "../../interfaces";
import { connect } from "react-redux";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  secondary: {
    color: theme.palette.text.secondary,
  },
}));

interface IAdjustMuteTime {
  title: string;
  type: string;
  decrease: (type: string) => void;
  increase: (type: string) => void;
  value: number;
}

const AdjustMuteTime = ({
  title,
  type,
  decrease,
  increase,
  value,
}: IAdjustMuteTime) => {
  return (
    <Grid
      item
      xs={12}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {/* Title */}
      <Typography variant="h6">{title}</Typography>
      <div
        style={{
          display: "flex",
          alignContent: "center",
        }}
      >
        <IconButton onClick={() => decrease(type)} disabled={value <= 0}>
          <Remove />
        </IconButton>
        <Typography
          variant="h6"
          style={{ display: "flex", alignItems: "center", padding: "0 1rem" }}
        >
          {value}
        </Typography>
        <IconButton onClick={() => increase(type)}>
          <Add />
        </IconButton>
      </div>
    </Grid>
  );
};

// Mute Functions
const postMute = functions.httpsCallable("postMute");

interface IMuteDialog {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: IPoint | any;
  // Reducer
  profile: IProfile;
  callback?: any;
}

const MuteDialog = ({
  open,
  setOpen,
  data,
  profile: { api, email },
  callback,
}: IMuteDialog) => {
  const classes = useStyles();

  const {
    id,
    currentAlarm: { buildingName, floorName, spaceName, deviceName } = {
      buildingName: "",
      floorName: "",
      spaceName: "",
      deviceName: "",
    },
    muteStatus: { muted, endTime } = { muted: false, endTime: 0 },
    name = "",
  } = data;

  const [openPicker, setOpenPicker] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    muted ? endTime : null
  );

  const [mutingPoint, setMutingPoint] = React.useState(false);

  const defaultMuteTime = {
    minutes: 0,
    hours: 0,
    days: 0,
  };
  const [muteTime, setMuteTime] = React.useState(defaultMuteTime);
  const { minutes, hours, days } = muteTime;

  const handleDateChange = (date: Date | null) => {
    setMuteTime(defaultMuteTime);
    setSelectedDate(date);
  };

  //  Close Dialog
  const handleClose = () => {
    setMuteTime(defaultMuteTime);
    setSelectedDate(null);
    setOpen(false);
  };

  //   Decrease / Increase
  const increase = React.useCallback(
    (type: string) => {
      if (selectedDate) {
        setSelectedDate(null);
      }
      //   @ts-ignore
      const value = muteTime[type] + 1;
      setMuteTime({ ...muteTime, [type]: value });
    },
    // eslint-disable-next-line
    [setMuteTime, muteTime]
  );

  const decrease = React.useCallback(
    (type: string) => {
      if (selectedDate) {
        setSelectedDate(null);
      }
      //   @ts-ignore
      const value = muteTime[type] - 1;
      setMuteTime({ ...muteTime, [type]: value <= 0 ? 0 : value });
    },
    // eslint-disable-next-line
    [setMuteTime, muteTime]
  );

  //   Mute Time
  const time = {
    minute: 60000,
    hour: 3600000,
    day: 86400000,
  };

  const handleSubmit = () => {
    const addTime = minutes * time.minute + hours * time.hour + days * time.day;
    const endTime = new Date().getTime() + addTime;
    let payload;
    if (selectedDate) {
      payload = new Date(selectedDate).getTime();
    } else {
      payload = endTime;
    }

    // Mute Point
    setMutingPoint(true);
    postMute({
      api,
      id,
      user: email,
      muted: !muted,
      startTime: !muted ? new Date().getTime() : 0,
      endTime: !muted ? payload : 0,
      route: "alarms/mute/point",
    })
      .then((res: any) => {
        setMutingPoint(false);
        if (callback) {
          callback();
        }
        handleClose();
      })
      .catch((e: any) => {
        setMutingPoint(false);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        disableBackdropClick
        disableEscapeKeyDown
      >
        {/* Title */}
        <DialogTitle id="form-dialog-title" style={{ paddingBottom: "0" }}>
          Mute {name}
        </DialogTitle>
        <Typography
          variant="caption"
          style={{ padding: "0 0 1rem 1.5rem" }}
          className={classes.secondary}
        >
          {buildingName}/{floorName}/{spaceName}/{deviceName}
        </Typography>

        {/* Body */}
        <DialogContent>
          <DialogContentText>
            Mute alarms on this point. To view your muted alarms, navigate to
            the point and click unmute.
          </DialogContentText>

          <AdjustMuteTime
            title={"Minutes"}
            type="minutes"
            increase={increase}
            decrease={decrease}
            value={minutes}
          />

          <AdjustMuteTime
            title={"Hours"}
            type="hours"
            increase={increase}
            decrease={decrease}
            value={hours}
          />

          <AdjustMuteTime
            title={"Days"}
            type="days"
            increase={increase}
            decrease={decrease}
            value={days}
          />

          <Divider style={{ margin: "1rem 0" }} />

          {/* Date */}
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* Title */}
            <Typography variant="h6">Until Date</Typography>
            <div
              style={{
                display: "flex",
                alignContent: "center",
              }}
            >
              <IconButton onClick={() => setOpenPicker(true)}>
                <Event />
              </IconButton>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  open={openPicker}
                  onOpen={() => setOpenPicker(true)}
                  onClose={() => setOpenPicker(false)}
                  disablePast
                  margin="normal"
                  value={selectedDate}
                  onChange={handleDateChange}
                  label=""
                  format="MM"
                  TextFieldComponent={() => null}
                  clearable
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>

          {/* Selected Date */}
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {selectedDate ? (
              <Typography variant="button" className={classes.secondary}>
                {moment(muted ? endTime : selectedDate).format(
                  "MMMM Do YYYY, h:mm a"
                )}
              </Typography>
            ) : muted && endTime ? (
              <Typography variant="button" className={classes.secondary}>
                Point muted until{" "}
                {moment(muted ? endTime : selectedDate).format(
                  "MMMM Do YYYY, h:mm a"
                )}
              </Typography>
            ) : null}
          </Grid>

          {/* Existing Mute Time */}
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button onClick={() => handleClose()} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit()}
            color="primary"
            disabled={
              mutingPoint ||
              (!Boolean(minutes) &&
                !Boolean(hours) &&
                !Boolean(days) &&
                !selectedDate)
            }
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default memo(
  connect((state: any) => ({
    profile: state.firebase.profile,
  }))(MuteDialog)
);
