import React, { ReactElement } from "react";
import { Typography, useTheme } from "@material-ui/core";

interface Props {
  title: string | JSX.Element;
  heading?: string;
  color?: "inherit" | "primary" | "secondary";
  style?: any;
  dense?: boolean;
  fixedHeight?: boolean;
}

export default function Title({
  color = "inherit",
  title,
  heading,
  style,
  dense = false,
  fixedHeight = false,
}: Props): ReactElement {
  let palette = useTheme().palette;
  const useColor = color === "inherit" ? "inherit" : palette[color]["main"];

  return (
    <div style={{ padding: dense ? 0 : "1rem 0", ...style }}>
      {heading && (
        <Typography
          color="primary"
          variant="overline"
          style={{
            paddingLeft: dense ? 0 : "1rem",
            fontWeight: 500,
            lineHeight: 1,
          }}
        >
          {heading}
        </Typography>
      )}
      <Typography
        variant="h6"
        style={{
          width: "100%",
          padding: dense ? 0 : "0 1rem .5rem 1rem",
          color: useColor,
          fontWeight: 300,
          height: fixedHeight ? 32 : "inherit",
          overflow: fixedHeight ? "hidden" : "inherit",
        }}
      >
        {title}
      </Typography>
    </div>
  );
}
