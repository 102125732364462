import React, { useState, useCallback, useEffect } from "react";

// Sockets
import useWebSocket, { ReadyState } from "react-use-websocket";

// Memo Check
const areEqual = (
  prevProps: { endpoint: string },
  nextProps: { endpoint: string }
) => {
  return prevProps.endpoint === nextProps.endpoint;
};

// Props
interface IWebsocket {
  endpoint: string;
  socketData: any;
  handleSocketData: (message: any) => void;
}

// MUI
export const Websocket = React.memo((props: IWebsocket) => {
  const { endpoint, socketData, handleSocketData } = props;

  const [socketUrl] = useState(
    "wss://ptxrcj27wa.execute-api.us-east-1.amazonaws.com/v1"
  );
  const [sendMessage, lastMessage, readyState, getWebSocket] = useWebSocket(
    socketUrl
  );

  const handleClickSendMessage = useCallback(
    () =>
      sendMessage(
        JSON.stringify({
          action: "subscribe",
          devices: [endpoint],
        })
      ),
    [endpoint, sendMessage]
  );

  // @ts-ignore
  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
  }[readyState];

  // Connect on load
  useEffect(() => {
    if (connectionStatus === "Open" && !socketData.id) {
      handleClickSendMessage();
    }
  }, [socketData.id, handleClickSendMessage, connectionStatus]);

  // Feed Messages
  useEffect(() => {
    if (lastMessage !== null) {
      handleSocketData(lastMessage);
    }
  }, [getWebSocket, handleSocketData, lastMessage]);

  return <span />;
}, areEqual);
