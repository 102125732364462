export const MClient = {
  id: "",
  name: "",
  sites: [],
};

export const MSite = {
  id: "",
  name: "",
  buildings: [],
};

export const MBuilding = {
  id: "",
  name: "",
  siteId: "",
  city: "",
  address: "",
  zip: "",
  contacts: [],
};
