import firebase from "firebase";

import "firebase/auth/dist/index.cjs";
import "firebase/database/dist/index.cjs";

let config;
if (
  process.env.NODE_ENV === "development" ||
  window.location.host.split(".")[0] === "staging"
) {
  config = {
    apiKey: "AIzaSyBwyzbD31AUGObDz1FqxOcM3kjZQokQ-Ig",
    authDomain: "alarms-dev.firebaseapp.com",
    databaseURL: "https://alarms-dev.firebaseio.com",
    projectId: "alarms-dev",
    storageBucket: "alarms-dev.appspot.com",
    messagingSenderId: "799579036446",
    appId: "1:799579036446:web:da02e852dae3e9a8b8070f",
  };
} else {
  config = {
    apiKey: "AIzaSyBwyzbD31AUGObDz1FqxOcM3kjZQokQ-Ig",
    authDomain: "alarms-dev.firebaseapp.com",
    databaseURL: "https://alarms-dev.firebaseio.com",
    projectId: "alarms-dev",
    storageBucket: "alarms-dev.appspot.com",
    messagingSenderId: "799579036446",
    appId: "1:799579036446:web:da02e852dae3e9a8b8070f",
  };
}

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

//Use Admin SDK
const auth = firebase.auth();
const db = firebase.database();
const functions = firebase.functions();
const store = firebase.firestore();
const storage = firebase.storage();

export { auth, db, functions, storage, store };
