import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";

// Interfaces
import { IProfile } from "../interfaces";
import { tapaAPI } from "../api";
import PointDataTable from "../components/PointPage/PointDataTable";
import { LinearProgress } from "@material-ui/core";

interface IPointPage {
  profile: IProfile;
}

const PointPage = ({ profile }: IPointPage) => {
  let { id } = useParams();

  const [loading, setLoading] = React.useState(false);
  const [pointData, setPointData] = React.useState({ id: "" });
  const [error, setError] = React.useState({ title: "", message: "" });

  const fetchPointData = useCallback(
    () => {
      if (!profile || !profile.api || !id) {
        return null;
      }
      setLoading(true);
      return tapaAPI({
        route: `points/${id}`,
        api: profile.api,
        callback: setPointData,
        error: () =>
          setError({
            title: "Error Fetching Point",
            message: "This has been logged and we are working on a solution",
          }),
      })
        .then((res: any) => {
          setLoading(false);
        })
        .catch((e: any) => {
          setLoading(false);
          setError({
            title: "Point Not Found",
            message:
              "The point at this address cannot be found, or you do not have access",
          });
        });
    },
    // eslint-disable-next-line
    [id, pointData, profile]
  );

  //   Load in point data on mount
  useEffect(() => {
    fetchPointData();
    return () => {
      setPointData({ id: "" });
      setError({ title: "", message: "" });
    };
  }, []);

  return (
    <div>
      {loading && <LinearProgress style={{ width: "100%" }} />}
      {Boolean(pointData.id) && (
        <PointDataTable
          point={pointData}
          profile={profile}
          fetchPointData={fetchPointData}
        />
      )}
    </div>
  );
};

export default connect((state: any) => ({
  profile: state.firebase.profile,
}))(PointPage);
