import React, { ReactElement, useCallback, useState, memo } from "react";

// MUI
import { useMediaQuery, useTheme } from "@material-ui/core";

// Components
import { AdminAppBar, AdminDrawer } from "./components";

const AdminLayout = (): ReactElement => {
  const theme = useTheme();

  //   Mobile / Desktop
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  //   Drawer Handlers
  const [open, setOpen] = useState(false);
  const openDesktop = isDesktop ? true : open;

  const handleDrawer = useCallback(
    (val: boolean) => {
      setOpen((o) => val || !o);
    },
    [setOpen]
  );

  return (
    <div style={{ position: "relative", width: "100%", zIndex: 100 }}>
      <AdminAppBar handleDrawer={handleDrawer} />

      <AdminDrawer
        handleDrawer={handleDrawer}
        open={openDesktop}
        variant={isDesktop ? "persistent" : "temporary"}
      />
    </div>
  );
};

export default memo(AdminLayout);
