import React, { useContext, useEffect, useState } from "react";
import { functions } from "../firebase/firebase";

// Contexts
import { DataContext } from "../components/Contexts";

// MUI
import {
  makeStyles,
  Badge,
  CircularProgress,
  Grid,
  Theme,
  Collapse,
} from "@material-ui/core";

// MUI Lab
import { TreeView } from "@material-ui/lab";

// MUI Icons
import { ArrowDropDown, ArrowRight } from "@material-ui/icons";

// Selectors
import { connect } from "react-redux";
import { IDashboardClient, IProfile } from "../interfaces";

// Components
import StyledTreeItem from "../components/Tree/StyledTreeItem";

// Util
import { isEmpty } from "lodash";
import { buildingTree, deviceTree } from "../components/Tree/treeFunctions";
import { compose } from "redux";
import { SET_BUILDING_DATA } from "../actions";
import { DeviceDialog } from "../components/DeviceDialog";

const fetchEndpoint = functions.httpsCallable("fetchEndpoint");

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
  badge: {
    boxShadow: theme.shadows[1],
    marginRight: "3rem",
    color: "rgba(0, 0, 0, 0.54)",
    minWidth: "max-content",
  },
}));

interface IPoints {
  buildingData: any;
  clients: IDashboardClient[];
  profile: IProfile;
  setBuildings: (payload: any) => void;
}

const Points = ({ buildingData, profile, setBuildings }: IPoints) => {
  const classes = useStyles();

  const [, setError] = React.useState({
    title: "",
    message: "",
  });

  const [expanded, setExpanded] = React.useState([]);
  const [deviceExpanded, setDeviceExpanded] = React.useState([]);

  const [selected, setSelected] = React.useState("");
  const [deviceSelected, setDeviceSelected] = React.useState([]);

  const [data, setData] = useState({});
  const [deviceTreeData, setDeviceTreeData] = useState({});

  const [loadingBuilding, setLoadingBuilding] = React.useState("");
  const [selectedBuildingData, setSelectedBuildingData] = React.useState<any>(
    {}
  );
  const [selectedDevice, setSelectedDevice] = React.useState<any>({});

  const { client, site } = useContext(DataContext);

  const iconLibrary: any = {
    root: () => <i className="fas fa-sitemap"></i>,
    client: () => <i className="fas fa-user"></i>,
    site: () => <i className="fas fa-project-diagram"></i>,
    building: () => <i className="fas fa-building"></i>,
    floor: () => <i className="fas fa-building"></i>,
    space: () => <i className="fas fa-building"></i>,
    device: () => <i className="fas fa-building"></i>,
  };

  useEffect(() => {
    setSelectedBuildingData({});

    if (!site.id) {
      setData({});
    }

    if (site.id) {
      buildingTree({ site, setData, setExpanded });
      setDeviceTreeData({});
    }
  }, [client.id, site.id]);

  useEffect(() => {
    setDeviceTreeData({});
    setDeviceExpanded([]);
    setDeviceSelected([]);

    if (site.id && selectedBuildingData.id) {
      deviceTree({
        building: selectedBuildingData,
        setDeviceTreeData,
        setDeviceExpanded,
      });
    }
  }, [client.id, site.id, selectedBuildingData.id]);

  //   Fetch Building Data
  const fetchBuildingData = (id: string) => {
    if (buildingData[id]) {
      setSelectedBuildingData(buildingData[id]);
      return null;
    }

    setLoadingBuilding(id);

    fetchEndpoint({ route: `buildings/${id}`, api: profile.api })
      .then((res) => {
        if (res && Array.isArray(JSON.parse(res.data))) {
          setBuildings(JSON.parse(res.data));
          setSelectedBuildingData(JSON.parse(res.data)[0]);
        }
        setLoadingBuilding("");
      })
      .catch((e: Error) => {
        setError({
          title: "Error fetching buildings",
          message: `There was an error fetching buildings at building ID: ${id} `,
        });
        setLoadingBuilding("");
      });
  };

  // Open Device Modal
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setSelectedDevice({});
    setOpen(false);
  };

  const openDeviceDialog = (data: any) => {
    setSelectedDevice(data);
    setOpen(true);
  };

  //   Tree
  const renderTree = (nodes: any) => {
    if (!nodes) return;

    return (
      <StyledTreeItem
        key={nodes.id}
        nodeId={nodes.id}
        labelText={nodes.name}
        labelIcon={iconLibrary[nodes.type]}
        labelInfo={
          loadingBuilding === nodes.id ? (
            <CircularProgress size={10} style={{ color: "white" }} />
          ) : nodes.type === "building" ? (
            !buildingData[nodes.id] ? (
              <ArrowRight />
            ) : (
              <Badge
                classes={{ badge: classes.badge }}
                badgeContent={`${
                  buildingData[nodes.id].devices.length
                } Devices`}
                color={
                  buildingData[nodes.id].devices.length
                    ? "primary"
                    : "secondary"
                }
                showZero
              />
            )
          ) : nodes.type === "device" && nodes.data.points.length ? (
            <Badge
              classes={{ badge: classes.badge }}
              badgeContent={`${nodes.data.points.length} Points`}
              color={nodes.data.points.length ? "primary" : "secondary"}
              showZero
            />
          ) : (
            ""
          )
        }
        onLabelClick={
          nodes.type === "building" && !loadingBuilding
            ? () => fetchBuildingData(nodes.id)
            : nodes.type === "device"
            ? () => openDeviceDialog(nodes.data)
            : () => {}
        }
      >
        {Array.isArray(nodes.children) && nodes.children.length
          ? nodes.children.map((node: any) => renderTree(node))
          : null}
      </StyledTreeItem>
    );
  };

  //   Handlers
  const handleToggle = (event: any, nodeIds: any) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: any, nodeIds: any) => {
    setSelected(nodeIds);
  };

  const handleToggleDevice = (event: any, nodeIds: any) => {
    setDeviceExpanded(nodeIds);
  };

  const handleSelectDevice = (event: any, nodeIds: any) => {
    setDeviceSelected(nodeIds);
  };

  return (
    <Grid container style={{ padding: "1rem" }}>
      {/* MUI Tree */}
      <Grid item xs={12} sm={6}>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ArrowDropDown />}
          defaultExpandIcon={<ArrowRight />}
          defaultEndIcon={<div style={{ width: 24 }} />}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
        >
          {!isEmpty(data) && renderTree(data)}
        </TreeView>
      </Grid>

      {/* Building Data */}
      <Grid item xs={12} sm={6}>
        <Collapse
          in={
            selectedBuildingData.floors &&
            Boolean(selectedBuildingData.floors.length)
          }
        >
          {selectedBuildingData.floors &&
            Boolean(selectedBuildingData.floors.length) && (
              <TreeView
                className={classes.root}
                defaultCollapseIcon={<ArrowDropDown />}
                defaultExpandIcon={<ArrowRight />}
                defaultEndIcon={<div style={{ width: 24 }} />}
                expanded={deviceExpanded}
                selected={deviceSelected}
                onNodeToggle={handleToggleDevice}
                onNodeSelect={handleSelectDevice}
              >
                {!isEmpty(deviceTreeData) && renderTree(deviceTreeData)}
              </TreeView>
            )}
        </Collapse>
      </Grid>

      <DeviceDialog
        open={open}
        handleClose={handleClose}
        selectedDevice={selectedDevice}
      />
    </Grid>
  );
};

export default compose(
  connect(
    (state: any) => ({
      buildingData: state.api.buildingData,
      clients: state.api.clients,
      profile: state.firebase.profile,
    }),
    (dispatch: any) => ({
      setBuildings: (payload: any) =>
        dispatch({
          type: SET_BUILDING_DATA,
          payload,
        }),
    })
  )
)(Points);
