import React, { useContext } from "react";

import { push } from "react-router-redux";
// Contexts
import { DataContext } from "../components/Contexts";
import { connect } from "react-redux";

interface ILanding {
  reroute: (route: string) => void;
}

const Landing = ({ reroute }: ILanding) => {
  const { client, site } = useContext(DataContext);

  console.log(client, site);
  if (client.id && site.id) {
    reroute("/alarms/current");
    return <span />;
  }

  return <div></div>;
};

export default connect(
  (state: any) => ({
    clients: state.api.clients,
  }),
  (dispatch) => ({
    reroute: (route: string) => dispatch(push(route)),
  })
)(Landing);
