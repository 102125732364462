import React from "react";

// Components
import Alarm from "./Alarm";

// Interfaces
import { IPoint, IProfile } from "../../interfaces";

interface IPointDataTable {
  point: IPoint | any;
  profile: IProfile;
  fetchPointData: () => void;
}

const PointDataTable = ({
  point,
  profile,
  fetchPointData,
}: IPointDataTable) => {
  console.log(point);
  return (
    <div>
      <Alarm point={point} profile={profile} fetchPointData={fetchPointData} />
    </div>
  );
};

export default PointDataTable;
