import React, { memo } from "react";

// MUI
import { makeStyles, Theme } from "@material-ui/core";

// Recharts
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// Time
import moment from "moment";

interface Props {
  data: any[] | never[];
  xDataKey: string;
  yDataKey?: string;
  xDataKeyLabel?: string;
  yDataKeyLabel?: string;
  responsiveWidth?: boolean;
}

// Tooltip
interface ICustomTooltip {
  active: boolean;
  payload: any;
  label: any;
  classes: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.paper,
    opacity: 0.75,
    border: "1px solid #ccc",
    borderRadius: 4,
    padding: "1rem",
  },
}));

const CustomTooltip = ({ active, payload, label, classes }: ICustomTooltip) => {
  if (active) {
    return (
      <div className={classes.tooltip}>
        <p className="label">{`${moment(label).format(
          "MMMM Do, h:mm a"
        )}: `}</p>
        <p>{`Value: ${payload[0].value.toFixed(2)}`}</p>
      </div>
    );
  }

  return null;
};

const HistoryChart = ({
  data,
  xDataKey,
  yDataKey,
  xDataKeyLabel,
  yDataKeyLabel,
  responsiveWidth,
}: Props) => {
  const classes = useStyles();

  return (
    <div style={{ margin: responsiveWidth ? "0 2rem" : "1rem 0" }}>
      <ResponsiveContainer width={responsiveWidth ? "100%" : 300} height={200}>
        <LineChart data={data} width={300} height={200}>
          <XAxis
            dataKey={xDataKeyLabel}
            tickFormatter={(timeStr) =>
              moment(timeStr).format("MMMM Do, h:mm a")
            }
          />

          <YAxis />
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
          <Line
            type="monotone"
            dot={false}
            dataKey={xDataKey}
            stroke="#8884d8"
          />
          <Tooltip
            viewBox={{ x: 0, y: 210, width: 300, height: 100 }}
            content={(props: any) => (
              <CustomTooltip {...props} classes={classes} />
            )}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default memo(HistoryChart);
