import {
  IDashboardClient,
  IBuilding,
  ISite,
  IBuildingData,
  IFloor,
  IDevice,
  ISpace,
} from "../../interfaces";

// interface IBuildTree {
//   clients: IDashboardClient[];
//   setData: (data: any) => void;
//   setExpanded: (nodes: any) => void;
// }

// export const buildTree = ({ clients, setData, setExpanded }: IBuildTree) => {
//   let nodes: any = ["root"];
//   let tree: any = {
//     type: "root",
//     id: "root",
//     name: "Building Directory",
//     data: {},
//     children: [],
//   };

//   clients.forEach(({ client }: IDashboardClient) => {
//     nodes.push(client.id);

//     tree.children.push({
//       type: "client",
//       id: client.id,
//       name: client.name,
//       data: client,
//       children: client.sites.map((site: ISite) => {
//         nodes.push(site.id);

//         return {
//           type: "site",
//           id: site.id,
//           name: site.name,
//           data: site,
//           children: site.buildings.map((building: IBuilding) => {
//             nodes.push(building.id);

//             return {
//               type: "building",
//               id: building.id,
//               name: building.name,
//               data: building,
//               children: [],
//             };
//           }),
//         };
//       }),
//     });
//     setData(tree);
//   });
//   setExpanded(nodes);
// };

interface IBuildingTree {
  site: ISite;
  setData: (data: any) => void;
  setExpanded: (nodes: any) => void;
}

export const buildingTree = ({ site, setData, setExpanded }: IBuildingTree) => {
  let nodes: any = ["root"];
  let tree: any = {
    type: "root",
    id: "root",
    name: "Building Directory",
    data: {},
    children: [],
  };

  site.buildings.forEach((building: IBuilding) => {
    nodes.push(building.id);

    tree.children.push({
      type: "building",
      id: building.id,
      name: building.name,
      data: building,
      children: [],
    });
    setData(tree);
  });
  setExpanded(nodes);
};

interface IDeviceTree {
  building: IBuilding;
  setDeviceTreeData: (data: any) => void;
  setDeviceExpanded: (nodes: any) => void;
}

export const deviceTree = ({
  building,
  setDeviceTreeData,
  setDeviceExpanded,
}: IDeviceTree) => {
  let nodes: any = ["root"];
  let tree: any = {
    type: "root",
    id: "root",
    name: "Device Directory",
    data: {},
    children: [],
  };

  building.floors.forEach((floor: any) => {
    nodes.push(floor.id);

    if (!floor.spaces.length) return;

    tree.children.push({
      type: "floor",
      id: floor.id,
      name: floor.name,
      data: floor,
      children: floor.spaces.map((space: any) => {
        if (!space.devices.length) return;

        return {
          type: "space",
          id: space.id,
          name: space.name,
          data: space,
          children: !Boolean(space.devices.length)
            ? []
            : space.devices.map((device: IDevice) => {
                return {
                  type: "device",
                  id: device.id,
                  name: device.name,
                  data: device,
                };
              }),
        };
      }),
    });
    setDeviceTreeData(tree);
  });
  setDeviceExpanded(nodes);
};
