import React, { useCallback } from "react";
import { connect } from "react-redux";
import { useFirebase } from "react-redux-firebase";

// Firebase
import firebase from "firebase";
import { storage } from "../../firebase/firebase";

// Util
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";

// MUI
import { makeStyles, Theme, Button, CircularProgress } from "@material-ui/core";

// MUI Icons
import { Add } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: 100,
    height: 100,
    background: "transparent",
    border: `3px solid #ccc`,
    borderRadius: "100%",
  },
  addIcon: {
    color: `#ccc`,
  },
  addIconProgress: {
    color: theme.palette.primary.main,
  },
}));

interface IFileUploader {
  uid: string;
  variant: string;
}

const FileUploader = ({ uid, variant }: IFileUploader) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [progress, setProgress] = React.useState(0);

  // Update
  const firebaseRef = useFirebase();

  // Root
  const storageRef = storage.ref();

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const fileRef = storageRef.child(`${uid}/${file.name}`);

    // Fileref
    const upload = fileRef.put(file);

    // Progress
    upload.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

        switch (snapshot.state) {
          case firebase.storage.TaskState.RUNNING:
            console.log("Upload is running");
            break;
        }
      },
      (error: any) => {
        switch (error.code) {
          case "storage/unauthorized":
            break;

          case "storage/canceled":
            break;

          case "storage/unknown":
            break;
        }
      },
      () => {
        upload.snapshot.ref
          .getDownloadURL()
          .then((downloadURL: string) => {
            if (variant === "avatar") {
              firebaseRef.updateProfile({ avatar: downloadURL });
            }
          })
          .then((res: any) => {
            setProgress(0);
            enqueueSnackbar("Avatar Upload Successful!", {
              variant: "success",
            });
          });
      }
    );
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} style={{ position: "relative" }}>
      {/* Loading Progress Bar */}
      <CircularProgress
        variant="determinate"
        size={100}
        style={{ position: "absolute", left: 0 }}
        value={progress}
        thickness={4}
      />
      <Button className={classes.avatar}>
        <div style={{ display: "flex", position: "relative" }}>
          {/* Icon */}
          <Add
            className={clsx({
              [classes.addIcon]: !Boolean(progress),
              [classes.addIconProgress]: Boolean(progress),
            })}
          />

          {/* Input Handler */}
          <input {...getInputProps()} />
        </div>
      </Button>
    </div>
  );
};

export default connect((store) => ({
  // @ts-ignore
  uid: store.firebase.auth.uid,
}))(FileUploader);
