import React, { memo, useContext, useCallback } from "react";
import { connect } from "react-redux";

//MUI
import {
  AppBar,
  CircularProgress,
  Button,
  Toolbar,
  // Util
  makeStyles,
  createStyles,
  // Interfaces
  Theme,
} from "@material-ui/core";

// MUI Icons
import { Brightness2, Brightness2Outlined, Menu } from "@material-ui/icons";

// MUI Colors
import { lightBlue } from "@material-ui/core/colors";

// Utils
import clsx from "clsx";
import { isLoaded } from "react-redux-firebase";
import { useAnimations } from "../../../../animations";
import { orderBy } from "lodash";

// Interfaces
import {
  IAuth,
  IProfile,
  IDashboardClient,
  ISite,
  IClient,
} from "../../../../interfaces";

// Contexts
import { DataContext, ThemeContext } from "../../../Contexts";

// Dev
import { AlarmMenu } from "../../../Alarms";

// Components
import MenuSelector from "../../../MenuSelector";
import { SET_CLIENTS } from "../../../../actions";

// Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // Base
    root: {
      flexGrow: 1,
    },
    toolbar: {
      paddingLeft: "2rem",
      display: "flex",
      justifyContent: "space-between",
      color: theme.palette.text.secondary,
      height: 84,
    },
    flexGrow: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
    // Logo
    imgWrapper: {
      display: "flex",
      alignItems: "center",
      borderRadius: 4,
      height: 40,
    },
    paper: {
      background: theme.palette.background.paper,
    },
    divider: {
      height: 48,
      width: "1px",
      background: "#cccccc",
    },
    appBarButton: {
      height: 48,
      minWidth: 48,
      width: 48,
      borderRadius: 0,
    },
  })
);

// Interfaces
interface Props {
  auth: IAuth;
  clients: IDashboardClient[];
  profile: IProfile;
  setClients: (clients: Array<IDashboardClient>) => void;
  handleDrawer: any;
}

const AdminAppBar = ({ clients, setClients, profile, handleDrawer }: Props) => {
  const classes = useStyles();
  const animations = useAnimations();

  const [alarms] = React.useState<any>([]);
  const [openAlarms, setOpenAlarms] = React.useState(false);

  const { darkMode, setDarkMode } = useContext(ThemeContext);

  /*

  Dev Only

  */

  // Client Selector
  // eslint-disable-next-line
  const { client, site, setClient, setSite } = useContext(DataContext);
  const selectClient = useCallback(
    (client: IClient) => {
      setClient(client);
      if (Boolean(client.sites.length)) {
        setSite(orderBy(client.sites, ["name"])[0]);
      } else {
        setSite({ id: "", name: "", buildings: [] });
      }
    },
    [client, site, setClient, setSite]
  );

  const selectSite = useCallback(
    (site: ISite) => {
      setSite(site);
    },
    [setSite]
  );

  // Menu Items
  const menuItems = React.useMemo(() => {
    return orderBy(clients, (client) => client.client.name).map(
      (client: IDashboardClient) => ({
        label: client.client.name,
        data: client.client,
      })
    );
  }, [clients]);

  let siteMenuItems = React.useMemo(() => {
    if (client.sites.length) {
      return orderBy(client.sites, ["name"]).map((site: ISite) => ({
        label: site.name,
        data: site,
      }));
    }

    return [];
  }, [client.id]);

  return (
    <div className={clsx(classes.root, animations.fadeIn)}>
      <AppBar position="fixed" classes={{ colorPrimary: classes.paper }}>
        <Toolbar className={classes.toolbar}>
          {/* Logo */}
          {!isLoaded(profile) ? (
            <CircularProgress size={20} />
          ) : (
            <span style={{ display: "flex" }}>
              <MenuSelector
                menuItems={menuItems}
                selector={selectClient}
                title="Client"
              />
              <MenuSelector
                menuItems={siteMenuItems}
                selector={selectSite}
                title="Site"
              />
            </span>
          )}

          {/* Flex */}
          <div className={classes.flexGrow} />

          {/* Menu */}
          <Button
            color="inherit"
            onClick={() => setDarkMode(!darkMode)}
            className={classes.appBarButton}
          >
            {!darkMode ? (
              <Brightness2Outlined />
            ) : (
              <Brightness2 style={{ color: lightBlue[300] }} />
            )}
          </Button>

          <Button
            color="inherit"
            onClick={() => handleDrawer(true)}
            className={classes.appBarButton}
          >
            <Menu />
          </Button>
        </Toolbar>
      </AppBar>
      <Toolbar className={classes.toolbar} />

      {/* Drawers */}
      <AlarmMenu
        open={openAlarms}
        handleClose={() => setOpenAlarms(false)}
        alarms={alarms}
      />
    </div>
  );
};

export default connect(
  (state: any) => ({
    auth: state.firebase.auth,
    clients: state.api.clients,
    profile: state.firebase.profile,
  }),

  (dispatch: any) => ({
    setClients: (clients: Array<IDashboardClient>) =>
      dispatch({ type: SET_CLIENTS, payload: clients }),
  })
)(memo(AdminAppBar));
