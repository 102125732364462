import React, { useContext } from "react";
import { connect } from "react-redux";

// Interfaces
import { IProfile } from "../interfaces";

// MUI
import { Grid, LinearProgress, Typography } from "@material-ui/core";

// Components
import AlarmTable from "../components/Alarms/AlarmTable";

// Context / API
import { tapaAPI } from "../api";
import { DataContext } from "../components/Contexts";

interface IAlarms {
  profile: IProfile;
}

const AlarmsCurrent = ({ profile }: IAlarms) => {
  // Context
  const { client, site } = useContext(DataContext);

  // Alarms
  const [alarms, setAlarms] = React.useState([]);
  const [fetchingAlarms, setFetchingAlarms] = React.useState(false);
  const [error, setError] = React.useState({ title: "", message: "" });

  const [fetchRoute, setFetchRoute] = React.useState({
    root: "",
    id: "",
    name: "",
  });

  React.useEffect(() => {
    if (site.id) {
      setFetchRoute({
        root: "Site",
        id: site.id,
        name: site.name,
      });
    }
  }, [site]);

  const handleFetchRoute = React.useCallback(
    (root: string, id: string, name: string) => {
      if (Boolean(root) && Boolean(id)) {
        setFetchRoute({ root, id, name });
      }
    },
    [setFetchRoute]
  );

  // Get Alarms
  const handleSetAlarms = React.useCallback(
    (alarms: any) => {
      if (alarms.alarms) {
        setAlarms(alarms.alarms);
      }
      setFetchingAlarms(false);
    },
    [setAlarms, setFetchingAlarms]
  );

  const handleFetchAlarms = (showLoading = true) => {
    if (error.title || error.message) {
      setError({ title: "", message: "" });
    }

    if (showLoading) {
      setFetchingAlarms(true);
    }

    if (profile.api && site.id && fetchRoute.root) {
      return tapaAPI({
        // @ts-ignore
        route: `alarms/current/${fetchRoute.root.toLowerCase()}/${
          fetchRoute.id
        }/0/100`,
        api: profile.api,
        callback: handleSetAlarms,
        error: () =>
          setError({
            title: "Error Fetching Alarms",
            message: "This has been logged and we are working on a solution",
          }),
      });
    }
  };

  // Fetch alarms
  React.useEffect(() => {
    if (Boolean(client.sites.length)) {
      handleFetchAlarms();
    }
    // eslint-disable-next-line
  }, [client, fetchRoute]);

  const tableProps = {
    title: `${fetchRoute.root} Alarms for ${fetchRoute.name}`,
  };

  return (
    <Grid container>
      {/* Loading */}
      {fetchingAlarms || !Boolean(client.id) ? (
        <LinearProgress
          style={{ width: "100%", height: 2, margin: ".5rem 0" }}
        />
      ) : null}

      {/* Title */}
      <Grid
        item
        xs={12}
        style={{
          padding: "3rem",
        }}
      >
        <Typography variant="h5" style={{ fontWeight: 300 }}>
          Current Alarms
        </Typography>
        <LinearProgress
          variant="determinate"
          value={100}
          color="primary"
          style={{ width: "100%", height: 2, margin: ".5rem 0" }}
        />
        <Typography variant="h6" style={{ fontWeight: 100 }}>
          {tableProps.title}
        </Typography>
      </Grid>

      {/* Progress / Table */}
      {Boolean(alarms.length) && !fetchingAlarms ? (
        <AlarmTable
          client={client}
          site={site}
          data={alarms}
          profile={profile}
          handleSetAlarms={handleSetAlarms}
          handleFetchAlarms={handleFetchAlarms}
          handleFetchRoute={handleFetchRoute}
          {...tableProps}
        />
      ) : null}
    </Grid>
  );
};

export default connect((state: any) => ({
  profile: state.firebase.profile,
}))(AlarmsCurrent);
