import React, { useCallback } from "react";

// MUI
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  GridListTileBar,
  IconButton,
  LinearProgress,
  Tooltip,
  makeStyles,
  Theme,
} from "@material-ui/core";

// MUI Colors
import { lightGreen } from "@material-ui/core/colors";

// MUI Icons
import { CheckCircle, Info, Refresh } from "@material-ui/icons";

// Components
import MaterialTable, { MTableToolbar } from "material-table";
import PointDialog from "../Point/PointDialog";

// Interfaces
import { IAlarm, IClient, ISite } from "../../interfaces";

// Util
import moment from "moment";
import { useSnackbar } from "notistack";

// API
import { tapaAPI } from "../../api";
import { functions } from "../../firebase/firebase";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: "100%",
  },
  listRoot: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
    marginRight: "1rem",
  },
}));

// Cloud Functions
const acknowledgeAlarmsAPI = functions.httpsCallable("acknowledgeAlarms");
const acknowledgeAlarms = ({
  api,
  email,
  payload,
}: {
  api: string;
  email: string;
  payload: string[];
}) =>
  acknowledgeAlarmsAPI({
    route: "alarms/acknowledge/event",
    api: api,
    user: email,
    payload,
  });

// MEMO
const areEqual = (prevProps: any, nextProps: any) => {
  return prevProps.data === nextProps.data && prevProps.site === nextProps.site;
};

interface IAlarmTable {
  client: IClient;
  site: ISite;
  data: IAlarm[];
  // Table Props
  title: string;
  profile: any;
  handleSetAlarms: (alarms: any[]) => any;
  handleFetchAlarms: (loading: boolean) => any;
  handleFetchRoute: (root: string, id: string, name: string) => any;
}

// Component
const AlarmTable = ({
  data,
  title,
  profile: { email, api },
  handleFetchAlarms,
  handleFetchRoute,
}: IAlarmTable) => {
  const classes = useStyles();

  // Row Selection
  const [selectedRows, setSelectedRows] = React.useState([]);
  const handleSelectionChange = useCallback((rowData) => {
    setSelectedRows(rowData);
  }, []);

  // Point Dialog State
  const [open, setOpen] = React.useState(false);
  const [pointData, setPointData] = React.useState({});
  const handlePointData = useCallback(
    (id: string) => {
      console.log(`points/${id}`, api);
      setOpen(true);
      return tapaAPI({
        route: `points/${id}`,
        api: api,
        callback: setPointData,
        error: () =>
          setError({
            title: "Error Fetching Point",
            message: "This has been logged and we are working on a solution",
          }),
      }).catch((e: any) => {
        console.log(e);
      });
    },
    // eslint-disable-next-line
    [pointData]
  );

  // Status State
  const [fetching, setFetching] = React.useState("");
  const [fetchingMultiple, setFetchingMultiple] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);
  const [loadingMore, setLoadingMore] = React.useState(false);

  // Util
  const { enqueueSnackbar } = useSnackbar();

  // Table Ref
  const materialTableRef = React.useRef(null);

  // Dialog Handlers
  const handleClose = useCallback(() => {
    setOpen(false);
    setPointData({});
    setFetching("");
  }, []);

  // Table Handlers
  const handleRefreshAlarms = () => {
    setRefreshing(true);
    handleFetchAlarms(false).then((res: any) => {
      setRefreshing(false);
    });
  };

  const handleLoadMore = () => {
    setLoadingMore(true);
    handleFetchAlarms(false);
  };

  // Acknowledge
  const handleAcknowledgeAlarm = useCallback((ids: string[]) => {
    setFetching(ids[0]);

    const acknowledgeAlarmPromise = acknowledgeAlarms({
      api,
      email,
      payload: ids,
    });

    return acknowledgeAlarmPromise.then((res: any) => {
      handleFetchAlarms(false)
        .then((res: any) => {
          setFetching("");
          enqueueSnackbar("Alarm Successfuly Acknowledged", {
            variant: "success",
          });
        })
        .catch((e: any) => {
          setFetching("");
        });
    });
    // eslint-disable-next-line
  }, []);

  // Acknowledge Multiple Alarms
  const handleAcknowledgeMultiple = useCallback((toolbarProps: any) => {
    setFetchingMultiple(true);
    const GUIDs = toolbarProps.selectedRows.map((alarm: IAlarm) => alarm.GUID);
    const acknowledgeAlarmPromise = acknowledgeAlarms({
      api,
      email,
      payload: GUIDs,
    });

    return acknowledgeAlarmPromise
      .then((res: any) => {
        if (materialTableRef && materialTableRef.current) {
          // @ts-ignore
          materialTableRef.current.onAllSelected(false);
        }
        enqueueSnackbar("Selected Alarms Successfully Acknowledged", {
          variant: "success",
        });
      })
      .catch((e: any) => {
        console.log(e);
      })
      .then((res: any) => {
        setFetchingMultiple(false);
        handleFetchAlarms(false);
      });
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const [error, setError] = React.useState({ title: "", message: "" });

  return (
    <div className={classes.table}>
      <MaterialTable
        tableRef={materialTableRef}
        style={{ boxShadow: "none" }}
        components={{
          Toolbar: (props) => {
            return (
              <div style={{ position: "relative" }}>
                <MTableToolbar {...props} />
                {fetching && <LinearProgress style={{ width: "100%" }} />}
                {Boolean(selectedRows.length) && (
                  <div style={{ position: "absolute", top: 8, right: "1rem" }}>
                    {fetchingMultiple ? (
                      <IconButton disabled>
                        <CircularProgress size={20} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => handleAcknowledgeMultiple(props)}
                        disabled={fetchingMultiple}
                      >
                        <CheckCircle />
                      </IconButton>
                    )}
                  </div>
                )}
              </div>
            );
          },
        }}
        data={data}
        title={""}
        columns={[
          { title: "Point", field: "pointName", filtering: false },
          {
            title: "Building",
            field: "location.buildingName",
            filtering: false,
          },
          { title: "Value", field: "alarmValue", filtering: false },
          { title: "Message", field: "alarmMessage", filtering: false },
          {
            title: "Time",
            field: "startTime",
            render: (rowData) => {
              return (
                <span style={{ display: "flex", flexDirection: "column" }}>
                  <span>
                    {moment(new Date(Number(rowData.startTime) * 1000)).format(
                      "MMMM Do, YYYY"
                    )}
                  </span>{" "}
                  <span>
                    {moment(new Date(Number(rowData.startTime) * 1000)).format(
                      "h:mm:ss a"
                    )}
                  </span>
                </span>
              );
            },
            filtering: false,
          },
          {
            hidden: Boolean(selectedRows.length) ? true : false,
            title: "Actions",
            field: "acknowledged",
            lookup: { 0: "Hide Acknowledged" },
            defaultFilter: ["0"],
            customFilterAndSearch: (term, rowData) => {
              if (term.length) {
                return !rowData.acknowledged;
              }

              return true;
            },
            filtering: true,
            render: (rowData: any) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  {fetching === rowData.GUID ? (
                    <IconButton disabled>
                      <CircularProgress size={20} />
                    </IconButton>
                  ) : (
                    <Tooltip placement="top" title="Acknowledge Point">
                      <span>
                        <IconButton
                          onClick={() => handleAcknowledgeAlarm([rowData.GUID])}
                          disabled={rowData.acknowledged}
                        >
                          <CheckCircle
                            style={{
                              color: rowData.acknowledged
                                ? lightGreen[500]
                                : "default",
                            }}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}

                  <Tooltip placement="top" title="Point Info">
                    <IconButton
                      onClick={() => handlePointData(rowData.pointId)}
                      aria-label={`info about ${rowData.pointName}`}
                    >
                      <Info />
                    </IconButton>
                  </Tooltip>
                </div>
              );
            },
          },
        ]}
        detailPanel={(rowData) => {
          return (
            <div className={classes.listRoot}>
              <GridListTileBar
                style={{ width: "100%", height: 68, position: "relative" }}
                title={rowData.pointName}
                subtitle={
                  <Breadcrumbs
                    separator={<span style={{ color: "white" }}>/</span>}
                  >
                    <Button
                      style={{ color: "white" }}
                      onClick={() =>
                        handleFetchRoute(
                          "Site",
                          rowData.location.siteId,
                          rowData.location.siteName
                        )
                      }
                    >
                      {rowData.location.siteName}
                    </Button>
                    <Button
                      style={{ color: "white" }}
                      onClick={() =>
                        handleFetchRoute(
                          "Building",
                          rowData.location.buildingId,
                          rowData.location.buildingName
                        )
                      }
                    >
                      {rowData.location.buildingName}
                    </Button>
                    <Button
                      style={{ color: "white" }}
                      onClick={() =>
                        handleFetchRoute(
                          "Floor",
                          rowData.location.floorId,
                          rowData.location.floorName
                        )
                      }
                    >
                      {rowData.location.floorName}
                    </Button>
                    <Button
                      style={{ color: "white" }}
                      onClick={() =>
                        handleFetchRoute(
                          "Space",
                          rowData.location.spaceId,
                          rowData.location.spaceName
                        )
                      }
                    >
                      {rowData.location.spaceName}
                    </Button>
                    <Button
                      style={{ color: "white" }}
                      onClick={() =>
                        handleFetchRoute(
                          "Device",
                          rowData.location.deviceId,
                          rowData.location.deviceName
                        )
                      }
                    >
                      {rowData.location.deviceName}
                    </Button>
                  </Breadcrumbs>
                }
              />
            </div>
          );
        }}
        onSelectionChange={handleSelectionChange}
        options={{
          exportButton: true,
          exportAllData: true,
          exportFileName: `${title} Alarms: ${moment(new Date()).format(
            "MMMM Do YYYY, h:mm:ss a"
          )}`,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          selection: true,
          search: Boolean(selectedRows.length) ? false : true,
          filtering: true,
        }}
      />

      {/* Refresh */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => handleRefreshAlarms()}
          disabled={refreshing || loadingMore}
        >
          {refreshing ? (
            <CircularProgress size={20} style={{ marginRight: ".5rem" }} />
          ) : (
            <Refresh style={{ marginRight: ".5rem" }} />
          )}
          Refresh Alarms
        </Button>
        <Button
          onClick={() => handleLoadMore()}
          disabled={loadingMore || refreshing}
        >
          {loadingMore ? (
            <CircularProgress size={20} style={{ marginRight: ".5rem" }} />
          ) : (
            <Refresh style={{ marginRight: ".5rem" }} />
          )}
          Load More
        </Button>
      </div>

      {/* Point Dialog */}
      <PointDialog
        open={open}
        handleClose={handleClose}
        pointData={pointData}
        handlePointData={handlePointData}
        handleAcknowledgeAlarm={handleAcknowledgeAlarm}
        error={error}
        setError={setError}
      />
    </div>
  );
};

export default React.memo(AlarmTable, areEqual);
