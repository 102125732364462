import { createContext } from "react";
import { MClient, MSite, MBuilding } from "../../models";

import AdminLayout from "../Layout/Admin";

export const DataContext = createContext({
  client: MClient,
  setClient: (client: any) => {},
  site: MSite,
  setSite: (site: any) => {},
  building: MBuilding,
  setBuilding: (building: any) => {},
});

export const ThemeContext = createContext({
  darkMode: false,
  setDarkMode: (mode: boolean) => {},
  layout: AdminLayout,
  setLayout: (layout: any) => {},
});
