import React, { useCallback } from "react";
import { functions } from "../../firebase/firebase";

// MUI
import {
  makeStyles,
  Theme,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  Typography,
  Button,
  Divider,
  Tabs,
  Tab,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  IconButton,
  Collapse,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";

// MUI Icons
import { Close, ExpandMore } from "@material-ui/icons";

// MUI Colors
import { green } from "@material-ui/core/colors";

// Util
import moment from "moment";

// Interfaces
import { IPoint, IProfile } from "../../interfaces";

// Components
import HistoryChart from "../Charts/HistoryChart";
import MuteDialog from "../Point/MuteDialog";
import { DeviceDialog } from "../DeviceDialog";

// API
import { tapaAPI } from "../../api";

const fetchHistory = functions.httpsCallable("fetchHistory");
const acknowledgeAlarmsAPI = functions.httpsCallable("acknowledgeAlarms");
const acknowledgeAlarms = ({
  api,
  email,
  payload,
}: {
  api: any;
  email: string;
  payload: string[];
}) =>
  acknowledgeAlarmsAPI({
    route: "alarms/acknowledge/event",
    api: api,
    user: email,
    payload,
  });

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
  },
  acknowledged: {
    background: green[500],
  },
  root: {
    width: "100%",
  },
  expansionPanel: {
    padding: "2rem",
  },
  heading: {
    fontSize: "1rem",
  },
  secondaryHeading: {
    fontSize: "1rem",
    color: theme.palette.text.secondary,
  },
  message: {
    fontSize: "1rem",
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  columnSmall: {
    flexBasis: "33.33%",
  },
  columnLarge: {
    flexBasis: "66.66%",
  },
  helper: {
    display: "flex",
    alignItems: "center",
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  tab: {
    display: "flex",
    textAlign: "left",
    padding: 0,
  },
  tabWrapper: {
    alignItems: "baseline",
  },
  flexContainerVertical: {
    alignItems: "baseline",
  },
}));

// Tab Panels
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ paddingLeft: "2rem" }}>{children}</Box>}
    </div>
  );
}

const formattedTime = (time: number) => {
  return moment(new Date(time * 1000)).format("MMMM Do YYYY, h:mm:ss a");
};

// Main Component
interface IAlarm {
  point: IPoint | any;
  profile: IProfile;
  fetchPointData: () => void;
}

const Alarm = ({ point, profile, fetchPointData }: IAlarm) => {
  const classes = useStyles();
  const { id, currentAlarm, data, muteStatus } = point;

  const {
    acknowledged,
    alarmMessage,
    buildingName,
    floorName,
    spaceName,
    deviceName,
    pointName,
    startTime,
    eventId,
  } = currentAlarm;

  const { displayString, status } = data;

  const { endTime, muted, user } = muteStatus;

  const location = `${buildingName}/${floorName}/${spaceName}/${deviceName}/${pointName}`;

  const [tab, setTab] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, tabIdx: number) => {
    setTab(tabIdx);
  };

  // Point Status Handlers
  const [openAcknowledged, setOpenAcknowledged] = React.useState(acknowledged);
  const [openMuted, setOpenMuted] = React.useState(muted);

  // Point History
  const [historyData, setHistoryData] = React.useState([]);
  const [historyError, setHistoryError] = React.useState({
    title: "",
    message: "",
  });
  const [fetchingHistory, setFetchingHistory] = React.useState(false);

  // Acknowledge Point
  const [acknowledgingAlarm, setAcknowledgingAlarm] = React.useState(false);
  const handleAcknowledgeAlarm = useCallback(() => {
    setAcknowledgingAlarm(true);
    const acknowledgeAlarmPromise = acknowledgeAlarms({
      api: profile.api,
      email: profile.email,
      payload: [eventId],
    }).then((res: any) => {
      fetchPointData();
      setAcknowledgingAlarm(false);
    });

    // eslint-disable-next-line
  }, []);

  // Mute Point
  const [openMuteDialog, setOpenMuteDialog] = React.useState(false);

  // Device Dialog
  const [deviceData, setDeviceData] = React.useState(null);
  const [loadingDevice, setLoadingDevice] = React.useState(false);
  const [loadingDeviceError, setLoadingDeviceError] = React.useState({
    title: "",
    message: "",
  });
  const [openDeviceDialog, setOpenDeviceDialog] = React.useState(false);
  const fetchDeviceData = useCallback(
    () => {
      if (!profile || !profile.api || !id) {
        return null;
      }
      setLoadingDevice(true);
      return tapaAPI({
        route: `devices/${point.deviceId}`,
        api: profile.api,
        callback: setDeviceData,
        error: () =>
          setLoadingDeviceError({
            title: "Error Fetching Device",
            message: "This has been logged and we are working on a solution",
          }),
      })
        .then((res: any) => {
          setLoadingDevice(false);
          setOpenDeviceDialog(true);
        })
        .catch((e: any) => {
          setLoadingDevice(false);
          setLoadingDeviceError({
            title: "Device Not Found",
            message:
              "The point at this address cannot be found, or you do not have access",
          });
        });
    },
    // eslint-disable-next-line
    []
  );

  React.useEffect(() => {
    if (!Boolean(historyData.length) && tab === 2) {
      setFetchingHistory(true);
      fetchHistory({
        route: `history/point/${id}`,
        api: profile.api,
        end: Math.floor(new Date().getTime() / 1000),
        start: Math.floor(new Date().getTime() / 1000) - 2592000,
      })
        .then((res: any) => {
          if (res.data && res.data.data) {
            setHistoryData(res.data.data);
          }
          setFetchingHistory(false);
        })
        .catch((e: any) => {
          setHistoryError(e);
          setFetchingHistory(false);
        });
    }
  }, [tab]);

  return (
    <div className={classes.root}>
      {/* Status Icons */}

      {acknowledged && (
        <Collapse in={acknowledged && openAcknowledged}>
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAcknowledged(false);
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            This alarm has been acknowledged!
          </Alert>
        </Collapse>
      )}

      {/* Status Icons */}
      {muted && (
        <Collapse in={muted && openMuted}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenMuted(false);
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            This alarm is currently muted!
          </Alert>
        </Collapse>
      )}

      <ExpansionPanel defaultExpanded className={classes.expansionPanel}>
        {/* Summary */}
        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
          <div className={classes.columnSmall}>
            <Typography className={classes.heading}>Current Alarm</Typography>
          </div>
          <div className={classes.columnLarge}>
            <Typography className={classes.secondaryHeading}>
              {alarmMessage} ({formattedTime(startTime)})
            </Typography>
          </div>
        </ExpansionPanelSummary>

        {/* Expanded */}
        <ExpansionPanelDetails className={classes.details}>
          <Grid container>
            <Grid item xs={2}>
              <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
                orientation="vertical"
                classes={{ flexContainer: classes.flexContainerVertical }}
              >
                <Tab
                  label="Data"
                  classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
                />
                <Tab
                  label="Mute"
                  classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
                />
                <Tab
                  label="History"
                  classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
                />
              </Tabs>
            </Grid>

            <Grid item xs={7}>
              {/* Data */}
              <TabPanel value={tab} index={0}>
                <List disablePadding>
                  <ListItem dense>
                    <ListItemText primary={"Value"} secondary={displayString} />
                  </ListItem>

                  <ListItem dense>
                    <ListItemText primary={"Status"} secondary={status} />
                  </ListItem>

                  <ListItem dense>
                    <ListItemText primary={"Location"} secondary={location} />
                  </ListItem>
                </List>
              </TabPanel>

              {/* Mute */}
              <TabPanel value={tab} index={1}>
                <List disablePadding>
                  <ListItem dense>
                    <ListItemText
                      primary={"Mute Status"}
                      secondary={
                        muted
                          ? "This point is currently muted"
                          : "This point is currently unmuted"
                      }
                    />
                  </ListItem>
                  {/* Start */}
                  {muted && (
                    <List disablePadding>
                      <ListItem dense>
                        <ListItemText
                          primary={"Start Time"}
                          secondary={formattedTime(muteStatus.startTime)}
                        />
                      </ListItem>

                      {/* End */}
                      <ListItem dense>
                        <ListItemText
                          primary={"End Time"}
                          secondary={formattedTime(muteStatus.endTime)}
                        />
                      </ListItem>

                      {/* User */}
                      <ListItem dense>
                        <ListItemText
                          primary={"Muted By User"}
                          secondary={muteStatus.user}
                        />
                      </ListItem>
                    </List>
                  )}
                </List>
              </TabPanel>

              {/* History */}
              <TabPanel value={tab} index={2}>
                {fetchingHistory ? (
                  <LinearProgress style={{ width: "100%" }} />
                ) : historyData.length ? (
                  <div style={{ marginLeft: "-2.5rem" }}>
                    <HistoryChart
                      data={historyData}
                      xDataKey="value"
                      xDataKeyLabel="timestamp"
                      responsiveWidth={true}
                    />
                  </div>
                ) : null}
              </TabPanel>
            </Grid>

            <Grid item xs={3} className={classes.helper}>
              <Typography variant="caption">
                View all points on this device
                <br />
                {loadingDevice ? (
                  <LinearProgress style={{ width: "100%" }} />
                ) : (
                  <a onClick={() => fetchDeviceData()} className={classes.link}>
                    Browse Device
                  </a>
                )}
              </Typography>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
          <Button
            size="small"
            onClick={() => setOpenMuteDialog(true)}
            disabled={muteStatus.muted}
          >
            Mute
          </Button>
          <Button
            size="small"
            color="primary"
            disabled={acknowledged || acknowledgingAlarm}
            onClick={() => handleAcknowledgeAlarm()}
            style={{ width: 98 }}
          >
            {acknowledgingAlarm ? (
              <LinearProgress style={{ width: "100%" }} />
            ) : !acknowledged ? (
              "Acknowledge"
            ) : (
              "Acknowledged"
            )}
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>

      {/* Dialogs */}
      <MuteDialog
        open={openMuteDialog}
        setOpen={setOpenMuteDialog}
        data={point}
        callback={() => fetchPointData()}
      />

      <DeviceDialog
        open={openDeviceDialog}
        handleClose={() => setOpenDeviceDialog(false)}
        selectedDevice={deviceData}
      />
    </div>
  );
};

export default Alarm;
